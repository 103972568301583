import styles from '@/components/style/index.module.less';
import lStyles from './style.module.less';

import React, { useEffect, useState } from 'react';
import { Button, message, Popconfirm, Result, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import { deleteKeyWordList, fetchKeyWordList, saveKeyWordList, updateKeyWordList, wrapRequest } from '@/api/subtitles';
import TextArea from 'antd/es/input/TextArea';


const App: React.FC = () => {
  const [nounId, setNounId] = useState<string | undefined>('init');
  const [isEdit, setIsEdit] = useState(true);
  const [words, setWords] = useState('');

  const { t } = useTranslation();
  // const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState<string | null>(null);
  const [succ, setSucc] = useState<string | null>(null);
  const [loadStatus, setLoadStatus] = useState({ open: false, close: false });

  useEffect(() => {
    if (!error) return;
    message.error(error, 5, () => {
      setError(null);
    });
  }, [error]);

  useEffect(() => {
    if (!succ) return;
    message.success(succ, 5, () => {
      setSucc(null);
    });
  }, [succ]);

  const getKeyWords = async () => {
    const { code, data: res } = await wrapRequest(fetchKeyWordList, {});

    console.log('keywords', res);
    if (code !== 'succ' || !res) {
      setError(t('ErrorfetchKeyWords'));
      return;
    }
    // succ
    if (res.data.noun_id) {
      // 创建list
      setNounId(res.data.noun_id);
      setWords(res.data.noun_list.join('\n'));
    } else {
      setNounId(undefined);
    }
  };

  async function openKeyWords () {
    setLoadStatus((prevLoad) => {
      return { ...prevLoad, open: true };
    });
    const { code, data: res } = await wrapRequest(saveKeyWordList, { noun_list: [''] });
    if (code !== 'succ' || !res) {
      setError(t('ErroropenKeyWords'));
    } else {
      await getKeyWords();
    }
    setLoadStatus((prevLoad) => {
      return { ...prevLoad, open: false };
    });
  }

  async function closeKeyWords () {
    if (!nounId) return;
    setLoadStatus((prevLoad) => {
      return { ...prevLoad, close: true };
    });
    const { code, data: res } = await wrapRequest(deleteKeyWordList, { noun_id: nounId });
    if (code !== 'succ' || !res) {
      setError(t('ErrordelKeyWords'));
    } else {
      await getKeyWords();
    }
    setLoadStatus((prevLoad) => {
      return { ...prevLoad, close: false };
    });
  }

  async function updateKeyWords (nounList: string[]) {
    // let nounList: string[] = keyWords.map(kw => kw.word.trim()).filter(word => word !== '');
    if (!nounId) return;
    if (!nounList.length) {
      nounList = [''];
    }
    const { code, data: res } = await wrapRequest(updateKeyWordList, { noun_id: nounId, noun_list: nounList })
    if (code !== 'succ' || !res) {
      console.log('>>>> error', code);
      if (code === 'fail') {
        setError(t('ErrorupdateKeyWords'));
      } else {
        setError(t(code));
      }
    } else {
      setSucc('修改成功');
    }
    return code === 'succ' ? 200 : 500;
  }

  useEffect(() => {
    getKeyWords();
  }, []);


  const addKeyWord = () => {
    setIsEdit(false);
  };

  async function update() {
    const ls = words.split('\n');
    const nls = ls.map(item => item.trim()).filter(item => item !== '');
    setIsEdit(true);
    setWords(nls.join('\n'));
    const status = await updateKeyWords(nls)
    if (status !== 200) {
      //  修改失败
      setIsEdit(false);
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log('Change:', e.target.value);
    setWords(e.target.value);
  };



  return (
    <div className={styles.pageContent}>
      <div className={styles.pageTitle}>{t('keyWordList')}</div>
      <div className={styles.translatorlog}>
        {!nounId && <Result
          title={t('keyWordWarn')}
          extra={
            <Button type="primary" key="console" loading={loadStatus.open} onClick={openKeyWords}>
              {t('keyWordOpen')}
            </Button>
          }
        />}
        {nounId && <>
          <div className={lStyles.hd}>
            {isEdit ?
              <Button className={lStyles.action} onClick={addKeyWord} > {t('keyWordAdd')} </Button> :
              <Button className={lStyles.action} onClick={update} > {t('keyWordSave')} </Button>}
            <Popconfirm
              title={t('keyWordClose')}
              description={t('keyWordDelWarn')}
              onConfirm={closeKeyWords}
              okText={t('keyWordDelOK')}
              cancelText={t('keyWordCancel')}
            >
              <Button className={lStyles.action} icon={<CloseOutlined />}
                loading={loadStatus.close} danger >
                {t('keyWordClose')}
              </Button>
            </Popconfirm>
            <Typography.Title className={lStyles.title} level={5}>{t('keyWordInputWarn')}</Typography.Title>
          </div>
          <TextArea rows={30} placeholder={t('keyWordInputHolder')} disabled={isEdit} onChange={onChange} value={words}/>
        </>
        }
      </div>

    </div>
  );
};

export default App;